/** @jsxRuntime classic */
/** @jsx jsx */
import ReactMarkdown from 'react-markdown'
import { jsx } from 'theme-ui'
import Box from '../Box'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  markdown: {
    width: '95%',
    maxWidth: ['360px', '1000px'],
    wordWrap: 'break-word ',
    color: '#000',
    lineHeight: '1.4rem',
    whiteSpace: 'pre-line',
    mx: '10px',
    li: {
      p: {
        position: 'relative',
        top: '0px',
        lineHeight: '1.4rem',
      },
    },
  },
}

const TC = ({ layout }) => {
  return (
    <Box sx={styles.container}>
      <ReactMarkdown sx={styles.markdown} source={layout?.tc} />
    </Box>
  )
}

export default TC
