/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from 'theme-ui'
import { useEffect, useRef } from 'react'
import { useModalDispatch } from '../../../../context/modal'

import Checkbox from '../../Fields/Checkbox'
import ErrorMessage from '../../Fields/Error'
import Label from '../../Fields/Label'

import ReactMarkdown from 'react-markdown'
import TC from '../../../Commons/TermAndConditions'

const styles = {
  fullWidth: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  selectOption: {
    background: 'bg',
    color: 'black',
  },
  markDown: {
    fontFamily: 'regular',
    width: ['90%', '95%', '95%'],
    p: {
      margin: 0,
    },
  },
  checkbox: {
    label: {
      div: {
        svg: {
          ':nth-of-type(0)': {
            color: '#495057',
          },
          ':nth-of-type(1)': {
            color: '#b70d21',
          },
        },
      },
      fontWeight: 'normal',
      a: {
        color: '#b70d21 !important',
        cursor: 'pointer',
      },
    },
  },
}

const QuestionCheckbox = ({ layout, question, useForm }) => {
  const modalDispatch = useModalDispatch()

  const conditionsLink = useRef(null)
  const privacyLink = useRef(null)

  const refs = [conditionsLink, privacyLink]

  const handleClick = (e) => {
    const lowerInnerText = e.target?.innerText?.toLowerCase()
    const isConditionsLink = (str) => {
      return (
        str.includes('conditions') ||
        str.includes('gewinnspielbedingungen') ||
        str.includes('villkor')
      )
    }
    const isConditions = isConditionsLink(lowerInnerText)
    if (isConditions) {
      e.preventDefault()
      modalDispatch({ type: 'show', payload: <TC layout={layout} /> })
    }
  }

  useEffect(() => {
    refs.forEach((element) => {
      if (element && element.current) {
        element?.current?.addEventListener('click', handleClick)
      }
    })

    return () => {
      refs.forEach((element) => {
        if (element && element.current) {
          element?.current?.removeEventListener('click', handleClick)
        }
      })
    }
  })

  const handleMarckdownLinkClick = (href) => {
    if (href.includes('privacy')) {
      window.open(href, '_ blank')
    }
    if (href.includes('/conditions')) {
      modalDispatch({
        type: 'show',
        payload: <TC layout={layout} />,
      })
    }
  }

  const questionOptInName = 'taylormade_bydb_2022_opt_in'
  const isQuestionOptIn = question.name === questionOptInName
  const checkPurpose = (value) => {
    useForm.setValue('socialMedia', value)
    useForm.setValue('digitalDisplay', value)
    useForm.setValue('emailOptin', value)
  }

  return (
    <div
      sx={{
        ...(question.isFullWidth && styles.fullWidth),
        variant: question.id
          ? 'forms.checkboxContainer.' + question.id
          : 'forms.checkboxContainer',
      }}
    >
      <div sx={styles.checkbox} key={question.name}>
        <Label sx={styles.centerStyle}>
          <Checkbox
            sx={styles.checkboxMinWidth}
            name={question.name}
            ref={useForm.register({
              ...question.registerConfig,
            })}
            value={question?.config?.value || ''}
            onClick={(ev) => isQuestionOptIn && checkPurpose(ev.target.checked)}
          />
          <ReactMarkdown
            sx={styles.markDown}
            source={question.label}
            renderers={{
              link: ({ href, children }) => {
                const refElement = href.includes('privacy')
                  ? privacyLink
                  : conditionsLink

                return (
                  <a
                    ref={refElement}
                    onClick={() => handleMarckdownLinkClick(href)}
                  >
                    {children}
                  </a>
                )
              },
            }}
          />
        </Label>
        {useForm.errors[question.name] &&
          useForm.errors[question.name].type === 'required' && (
            <ErrorMessage
              message={
                question.errorMessages && question.errorMessages.required
              }
            />
          )}
      </div>
    </div>
  )
}

export default QuestionCheckbox
