import CountryAndRegionsData from '../../forms/countryAndRegion'
import ErrorMessage from '../../Fields/Error'
import Select from '../../Fields/Select'
import Label from '../../Fields/Label'

/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from 'theme-ui'

const styles = {
  fullWidth: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
}

const prioritySort = (countries, order) => {
  const countryOrder = []

  order.filter((isoCountryCode) => {
    return countries.find((country) => {
      if (
        isoCountryCode.toString().toLowerCase() === country.cs.toLowerCase()
      ) {
        countryOrder.push(country)
      }
    })
  })

  const origin = countries.filter((item) => !order.includes(item.cs))

  return [...countryOrder, ...origin]
}

const QuestionCountry = ({ component, question, useForm, ...props }) => {
  const { errors, register, setValue, unregister, trigger } = useForm
  const CustomComponent = ({ component }) => component(question, useForm)

  const getCountriesOptions = (label, countries) => {
    const filteredCountries = prioritySort(
      countries,
      question.priorityOptions || [],
    )

    return [].concat(
      filteredCountries.map((country) => ({
        value: (question.returnCountryName && country.value) || country.cs,
        label: country.cn,
      })),
    )
  }

  const renderCountryOptions = (items) => {
    return items.map((item) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ))
  }

  const options = getCountriesOptions(
    question.placeholder,
    CountryAndRegionsData,
  )

  return component ? (
    <CustomComponent component={component} />
  ) : (
    <div
      sx={
        question.isFullWidth
          ? {
              ...(question.isFullWidth && styles.fullWidth),
              variant: 'forms.countryContainerFullWidth',
            }
          : {
              variant: 'forms.countryContainer',
            }
      }
    >
      {question.label && <Label>{question.label}</Label>}
      <Select
        onChange={() => {
          trigger(question.name)
        }}
        id={question.id}
        key={question.name}
        name={question.name}
        options={options}
        isSearchable={false}
        register={register}
        registerConfig={question.registerConfig}
        placeholder={question.placeholder}
        label={question.label}
        setValue={setValue}
        unregister={unregister}
        {...props}
      >
        {renderCountryOptions(
          getCountriesOptions(question.placeholder, CountryAndRegionsData),
        )}
      </Select>
      {errors[question.name] &&
        (errors[question.name].type === 'required' ||
          errors[question.name].type === 'noEmpty') && (
          <ErrorMessage
            message={question.errorMessages && question.errorMessages.required}
          />
        )}
    </div>
  )
}

export default QuestionCountry
