export default [
  {
    cn: 'Afghanistan',
    cs: 'AF',
    value: 'afghanistan',
  },
  {
    cn: 'Åland Islands',
    cs: 'AX',
    value: 'åland islands',
  },
  {
    cn: 'Albania',
    cs: 'AL',
    value: 'albania',
  },
  {
    cn: 'Algeria',
    cs: 'DZ',
    value: 'algeria',
  },
  {
    cn: 'American Samoa',
    cs: 'AS',
    value: 'american samoa',
  },
  {
    cn: 'Andorra',
    cs: 'AD',
    value: 'andorra',
  },
  {
    cn: 'Angola',
    cs: 'AO',
    value: 'angola',
  },
  {
    cn: 'Anguilla',
    cs: 'AI',
    value: 'anguilla',
  },
  {
    cn: 'Antarctica',
    cs: 'AQ',
    value: 'antarctica',
  },
  {
    cn: 'Antigua and Barbuda',
    cs: 'AG',
    value: 'antigua and barbuda',
  },
  {
    cn: 'Argentina',
    cs: 'AR',
    value: 'argentina',
  },
  {
    cn: 'Armenia',
    cs: 'AM',
    value: 'armenia',
  },
  {
    cn: 'Aruba',
    cs: 'AW',
    value: 'aruba',
  },
  {
    cn: 'Australia',
    cs: 'AU',
    value: 'australia',
  },
  {
    cn: 'Austria',
    cs: 'AT',
    value: 'austria',
  },
  {
    cn: 'Azerbaijan',
    cs: 'AZ',
    value: 'azerbaijan',
  },
  {
    cn: 'Bahamas',
    cs: 'BS',
    value: 'bahamas',
  },
  {
    cn: 'Bahrain',
    cs: 'BH',
    value: 'bahrain',
  },
  {
    cn: 'Bangladesh',
    cs: 'BD',
    value: 'bangladesh',
  },
  {
    cn: 'Barbados',
    cs: 'BB',
    value: 'barbados',
  },
  {
    cn: 'Belarus',
    cs: 'BY',
    value: 'belarus',
  },
  {
    cn: 'Belgium',
    cs: 'BE',
    value: 'belgium',
  },
  {
    cn: 'Belize',
    cs: 'BZ',
    value: 'belize',
  },
  {
    cn: 'Benin',
    cs: 'BJ',
    value: 'benin',
  },
  {
    cn: 'Bermuda',
    cs: 'BM',
    value: 'bermuda',
  },
  {
    cn: 'Bhutan',
    cs: 'BT',
    value: 'bhutan',
  },
  {
    cn: 'Bolivia',
    cs: 'BO',
    value: 'bolivia',
  },
  {
    cn: 'Bonaire, Sint Eustatius and Saba',
    cs: 'BQ',
    value: 'bonaire, sint eustatius and saba',
  },
  {
    cn: 'Bosnia and Herzegovina',
    cs: 'BA',
    value: 'bosnia and herzegovina',
  },
  {
    cn: 'Botswana',
    cs: 'BW',
    value: 'botswana',
  },
  {
    cn: 'Bouvet Island',
    cs: 'BV',
    value: 'bouvet island',
  },
  {
    cn: 'Brazil',
    cs: 'BR',
    value: 'brazil',
  },
  {
    cn: 'British Indian Ocean Territory',
    cs: 'IO',
    value: 'british indian ocean territory',
  },
  {
    cn: 'Brunei Darussalam',
    cs: 'BN',
    value: 'brunei darussalam',
  },
  {
    cn: 'Bulgaria',
    cs: 'BG',
    value: 'bulgaria',
  },
  {
    cn: 'Burkina Faso',
    cs: 'BF',
    value: 'burkina faso',
  },
  {
    cn: 'Burundi',
    cs: 'BI',
    value: 'burundi',
  },
  {
    cn: 'Cambodia',
    cs: 'KH',
    value: 'cambodia',
  },
  {
    cn: 'Cameroon',
    cs: 'CM',
    value: 'cameroon',
  },
  {
    cn: 'Canada',
    cs: 'CA',
    value: 'canada',
  },
  {
    cn: 'Cape Verde',
    cs: 'CV',
    value: 'cape verde',
  },
  {
    cn: 'Cayman Islands',
    cs: 'KY',
    value: 'cayman islands',
  },
  {
    cn: 'Central African Republic',
    cs: 'CF',
    value: 'central african republic',
  },
  {
    cn: 'Chad',
    cs: 'TD',
    value: 'chad',
  },
  {
    cn: 'Chile',
    cs: 'CL',
    value: 'chile',
  },
  {
    cn: 'China',
    cs: 'CN',
    value: 'china',
  },
  {
    cn: 'Christmas Island',
    cs: 'CX',
    value: 'christmas island',
  },
  {
    cn: 'Cocos (Keeling) Islands',
    cs: 'CC',
    value: 'cocos (keeling) islands',
  },
  {
    cn: 'Colombia',
    cs: 'CO',
    value: 'colombia',
  },
  {
    cn: 'Comoros',
    cs: 'KM',
    value: 'comoros',
  },
  {
    cn: 'Congo',
    cs: 'CG',
    value: 'congo',
  },
  {
    cn: 'Cook Islands',
    cs: 'CK',
    value: 'cook islands',
  },
  {
    cn: 'Costa Rica',
    cs: 'CR',
    value: 'costa rica',
  },
  {
    cn: "Côte d'Ivoire",
    cs: 'CI',
    value: "côte d'ivoire",
  },
  {
    cn: 'Croatia',
    cs: 'HR',
    value: 'croatia',
  },
  {
    cn: 'Cuba',
    cs: 'CU',
    value: 'cuba',
  },
  {
    cn: 'Curaçao',
    cs: 'CW',
    value: 'curaçao',
  },
  {
    cn: 'Cyprus',
    cs: 'CY',
    value: 'cyprus',
  },
  {
    cn: 'Czech Republic',
    cs: 'CZ',
    value: 'czech republic',
  },
  {
    cn: 'Denmark',
    cs: 'DK',
    value: 'denmark',
  },
  {
    cn: 'Djibouti',
    cs: 'DJ',
    value: 'djibouti',
  },
  {
    cn: 'Dominica',
    cs: 'DM',
    value: 'dominica',
  },
  {
    cn: 'Dominican Republic',
    cs: 'DO',
    value: 'dominican republic',
  },
  {
    cn: 'Ecuador',
    cs: 'EC',
    value: 'ecuador',
  },
  {
    cn: 'Egypt',
    cs: 'EG',
    value: 'egypt',
  },
  {
    cn: 'El Salvador',
    cs: 'SV',
    value: 'el salvador',
  },
  {
    cn: 'Equatorial Guinea',
    cs: 'GQ',
    value: 'equatorial guinea',
  },
  {
    cn: 'Eritrea',
    cs: 'ER',
    value: 'eritrea',
  },
  {
    cn: 'Estonia',
    cs: 'EE',
    value: 'estonia',
  },
  {
    cn: 'Ethiopia',
    cs: 'ET',
    value: 'ethiopia',
  },
  {
    cn: 'Falkland Islands (Malvinas)',
    cs: 'FK',
    value: 'falkland islands (malvinas)',
  },
  {
    cn: 'Faroe Islands',
    cs: 'FO',
    value: 'faroe islands',
  },
  {
    cn: 'Fiji',
    cs: 'FJ',
    value: 'fiji',
  },
  {
    cn: 'Finland',
    cs: 'FI',
    value: 'finland',
  },
  {
    cn: 'France',
    cs: 'FR',
    value: 'france',
  },
  {
    cn: 'French Guiana',
    cs: 'GF',
    value: 'french guiana',
  },
  {
    cn: 'French Polynesia',
    cs: 'PF',
    value: 'french polynesia',
  },
  {
    cn: 'French Southern Territories',
    cs: 'TF',
    value: 'french southern territories',
  },
  {
    cn: 'Gabon',
    cs: 'GA',
    value: 'gabon',
  },
  {
    cn: 'Gambia',
    cs: 'GM',
    value: 'gambia',
  },
  {
    cn: 'Georgia',
    cs: 'GE',
    value: 'georgia',
  },
  {
    cn: 'Germany',
    cs: 'DE',
    value: 'germany',
  },
  {
    cn: 'Ghana',
    cs: 'GH',
    value: 'ghana',
  },
  {
    cn: 'Gibraltar',
    cs: 'GI',
    value: 'gibraltar',
  },
  {
    cn: 'Greece',
    cs: 'GR',
    value: 'greece',
  },
  {
    cn: 'Greenland',
    cs: 'GL',
    value: 'greenland',
  },
  {
    cn: 'Grenada',
    cs: 'GD',
    value: 'grenada',
  },
  {
    cn: 'Guadeloupe',
    cs: 'GP',
    value: 'guadeloupe',
  },
  {
    cn: 'Guam',
    cs: 'GU',
    value: 'guam',
  },
  {
    cn: 'Guatemala',
    cs: 'GT',
    value: 'guatemala',
  },
  {
    cn: 'Guernsey',
    cs: 'GG',
    value: 'guernsey',
  },
  {
    cn: 'Guinea',
    cs: 'GN',
    value: 'guinea',
  },
  {
    cn: 'Guinea-Bissau',
    cs: 'GW',
    value: 'guinea-bissau',
  },
  {
    cn: 'Guyana',
    cs: 'GY',
    value: 'guyana',
  },
  {
    cn: 'Haiti',
    cs: 'HT',
    value: 'haiti',
  },
  {
    cn: 'Heard Island and Mcdonald Islands',
    cs: 'HM',
    value: 'heard island and mcdonald islands',
  },
  {
    cn: 'Holy See (Vatican City State)',
    cs: 'VA',
    value: 'holy see (vatican city state)',
  },
  {
    cn: 'Honduras',
    cs: 'HN',
    value: 'honduras',
  },
  {
    cn: 'Hong Kong',
    cs: 'HK',
    value: 'hong kong',
  },
  {
    cn: 'Hungary',
    cs: 'HU',
    value: 'hungary',
  },
  {
    cn: 'Iceland',
    cs: 'IS',
    value: 'iceland',
  },
  {
    cn: 'India',
    cs: 'IN',
    value: 'india',
  },
  {
    cn: 'Indonesia',
    cs: 'ID',
    value: 'indonesia',
  },
  {
    cn: 'Iran, Islamic Republic Of',
    cs: 'IR',
    value: 'iran, islamic republic of',
  },
  {
    cn: 'Iraq',
    cs: 'IQ',
    value: 'iraq',
  },
  {
    cn: 'Isle of Man',
    cs: 'IM',
    value: 'isle of man',
  },
  {
    cn: 'Israel',
    cs: 'IL',
    value: 'israel',
  },
  {
    cn: 'Italy',
    cs: 'IT',
    value: 'italy',
  },
  {
    cn: 'Jamaica',
    cs: 'JM',
    value: 'jamaica',
  },
  {
    cn: 'Japan',
    cs: 'JP',
    value: 'japan',
  },
  {
    cn: 'Jersey',
    cs: 'JE',
    value: 'jersey',
  },
  {
    cn: 'Jordan',
    cs: 'JO',
    value: 'jordan',
  },
  {
    cn: 'Kazakhstan',
    cs: 'KZ',
    value: 'kazakhstan',
  },
  {
    cn: 'Kenya',
    cs: 'KE',
    value: 'kenya',
  },
  {
    cn: 'Kiribati',
    cs: 'KI',
    value: 'kiribati',
  },
  {
    cn: "Korea, Democratic People's Republic of",
    cs: 'KP',
    value: "korea, democratic people's republic of",
  },
  {
    cn: 'Korea, Republic of',
    cs: 'KR',
    value: 'korea, republic of',
  },
  {
    cn: 'Kuwait',
    cs: 'KW',
    value: 'kuwait',
  },
  {
    cn: 'Kyrgyzstan',
    cs: 'KG',
    value: 'kyrgyzstan',
  },
  {
    cn: 'Laos',
    cs: 'LA',
    value: 'laos',
  },
  {
    cn: 'Latvia',
    cs: 'LV',
    value: 'latvia',
  },
  {
    cn: 'Lebanon',
    cs: 'LB',
    value: 'lebanon',
  },
  {
    cn: 'Lesotho',
    cs: 'LS',
    value: 'lesotho',
  },
  {
    cn: 'Liberia',
    cs: 'LR',
    value: 'liberia',
  },
  {
    cn: 'Libyan Arab Jamahiriya',
    cs: 'LY',
    value: 'libyan arab jamahiriya',
  },
  {
    cn: 'Liechtenstein',
    cs: 'LI',
    value: 'liechtenstein',
  },
  {
    cn: 'Lithuania',
    cs: 'LT',
    value: 'lithuania',
  },
  {
    cn: 'Luxembourg',
    cs: 'LU',
    value: 'luxembourg',
  },
  {
    cn: 'Macao',
    cs: 'MO',
    value: 'macao',
  },
  {
    cn: 'Madagascar',
    cs: 'MG',
    value: 'madagascar',
  },
  {
    cn: 'Malawi',
    cs: 'MW',
    value: 'malawi',
  },
  {
    cn: 'Malaysia',
    cs: 'MY',
    value: 'malaysia',
  },
  {
    cn: 'Maldives',
    cs: 'MV',
    value: 'maldives',
  },
  {
    cn: 'Mali',
    cs: 'ML',
    value: 'mali',
  },
  {
    cn: 'Malta',
    cs: 'MT',
    value: 'malta',
  },
  {
    cn: 'Marshall Islands',
    cs: 'MH',
    value: 'marshall islands',
  },
  {
    cn: 'Martinique',
    cs: 'MQ',
    value: 'martinique',
  },
  {
    cn: 'Mauritania',
    cs: 'MR',
    value: 'mauritania',
  },
  {
    cn: 'Mauritius',
    cs: 'MU',
    value: 'mauritius',
  },
  {
    cn: 'Mayotte',
    cs: 'YT',
    value: 'mayotte',
  },
  {
    cn: 'Mexico',
    cs: 'MX',
    value: 'mexico',
  },
  {
    cn: 'Micronesia, Federated States of',
    cs: 'FM',
    value: 'micronesia, federated states of',
  },
  {
    cn: 'Moldova, Republic of',
    cs: 'MD',
    value: 'moldova, republic of',
  },
  {
    cn: 'Monaco',
    cs: 'MC',
    value: 'monaco',
  },
  {
    cn: 'Mongolia',
    cs: 'MN',
    value: 'mongolia',
  },
  {
    cn: 'Montenegro',
    cs: 'ME',
    value: 'montenegro',
  },
  {
    cn: 'Montserrat',
    cs: 'MS',
    value: 'montserrat',
  },
  {
    cn: 'Morocco',
    cs: 'MA',
    value: 'morocco',
  },
  {
    cn: 'Mozambique',
    cs: 'MZ',
    value: 'mozambique',
  },
  {
    cn: 'Myanmar',
    cs: 'MM',
    value: 'myanmar',
  },
  {
    cn: 'Namibia',
    cs: 'NA',
    value: 'namibia',
  },
  {
    cn: 'Nauru',
    cs: 'NR',
    value: 'nauru',
  },
  {
    cn: 'Nepal',
    cs: 'NP',
    value: 'nepal',
  },
  {
    cn: 'Netherlands',
    cs: 'NL',
    value: 'netherlands',
  },
  {
    cn: 'Netherlands Antilles',
    cs: 'AN',
    value: 'netherlands antilles',
  },
  {
    cn: 'New Caledonia',
    cs: 'NC',
    value: 'new caledonia',
  },
  {
    cn: 'New Zealand',
    cs: 'NZ',
    value: 'new zealand',
  },
  {
    cn: 'Nicaragua',
    cs: 'NI',
    value: 'nicaragua',
  },
  {
    cn: 'Niger',
    cs: 'NE',
    value: 'niger',
  },
  {
    cn: 'Nigeria',
    cs: 'NG',
    value: 'nigeria',
  },
  {
    cn: 'Niue',
    cs: 'NU',
    value: 'niue',
  },
  {
    cn: 'Norfolk Island',
    cs: 'NF',
    value: 'norfolk island',
  },
  {
    cn: 'North Macedonia',
    cs: 'MK',
    value: 'north macedonia',
  },
  {
    cn: 'Northern Mariana Islands',
    cs: 'MP',
    value: 'northern mariana islands',
  },
  {
    cn: 'Norway',
    cs: 'NO',
    value: 'norway',
  },
  {
    cn: 'Oman',
    cs: 'OM',
    value: 'oman',
  },
  {
    cn: 'Pakistan',
    cs: 'PK',
    value: 'pakistan',
  },
  {
    cn: 'Palau',
    cs: 'PW',
    value: 'palau',
  },
  {
    cn: 'Palestinian Territory, Occupied',
    cs: 'PS',
    value: 'palestinian territory, occupied',
  },
  {
    cn: 'Panama',
    cs: 'PA',
    value: 'panama',
  },
  {
    cn: 'Papua New Guinea',
    cs: 'PG',
    value: 'papua new guinea',
  },
  {
    cn: 'Paraguay',
    cs: 'PY',
    value: 'paraguay',
  },
  {
    cn: 'Peru',
    cs: 'PE',
    value: 'peru',
  },
  {
    cn: 'Philippines',
    cs: 'PH',
    value: 'philippines',
  },
  {
    cn: 'Pitcairn',
    cs: 'PN',
    value: 'pitcairn',
  },
  {
    cn: 'Poland',
    cs: 'PL',
    value: 'poland',
  },
  {
    cn: 'Portugal',
    cs: 'PT',
    value: 'portugal',
  },
  {
    cn: 'Puerto Rico',
    cs: 'PR',
    value: 'puerto rico',
  },
  {
    cn: 'Qatar',
    cs: 'QA',
    value: 'qatar',
  },
  {
    cn: 'Republic of Ireland',
    cs: 'IE',
    value: 'ireland',
  },
  {
    cn: 'Reunion',
    cs: 'RE',
    value: 'reunion',
  },
  {
    cn: 'Romania',
    cs: 'RO',
    value: 'romania',
  },
  {
    cn: 'Russian Federation',
    cs: 'RU',
    value: 'russian federation',
  },
  {
    cn: 'Rwanda',
    cs: 'RW',
    value: 'rwanda',
  },
  {
    cn: 'Saint Barthélemy',
    cs: 'BL',
    value: 'saint barthélemy',
  },
  {
    cn: 'Saint Helena, Ascension and Tristan de Cunha',
    cs: 'SH',
    value: 'saint helena, ascension and tristan de cunha',
  },
  {
    cn: 'Saint Kitts and Nevis',
    cs: 'KN',
    value: 'saint kitts and nevis',
  },
  {
    cn: 'Saint Lucia',
    cs: 'LC',
    value: 'saint lucia',
  },
  {
    cn: 'Saint Martin',
    cs: 'MF',
    value: 'saint martin',
  },
  {
    cn: 'Saint Pierre and Miquelon',
    cs: 'PM',
    value: 'saint pierre and miquelon',
  },
  {
    cn: 'Saint Vincent and the Grenadines',
    cs: 'VC',
    value: 'saint vincent and the grenadines',
  },
  {
    cn: 'Samoa',
    cs: 'WS',
    value: 'samoa',
  },
  {
    cn: 'San Marino',
    cs: 'SM',
    value: 'san marino',
  },
  {
    cn: 'Sao Tome and Principe',
    cs: 'ST',
    value: 'sao tome and principe',
  },
  {
    cn: 'Saudi Arabia',
    cs: 'SA',
    value: 'saudi arabia',
  },
  {
    cn: 'Senegal',
    cs: 'SN',
    value: 'senegal',
  },
  {
    cn: 'Serbia and Montenegro',
    cs: 'CS',
    value: 'serbia and montenegro',
  },
  {
    cn: 'Seychelles',
    cs: 'SC',
    value: 'seychelles',
  },
  {
    cn: 'Sierra Leone',
    cs: 'SL',
    value: 'sierra leone',
  },
  {
    cn: 'Singapore',
    cs: 'SG',
    value: 'singapore',
  },
  {
    cn: 'Sint Maarten (Dutch part)',
    cs: 'SX',
    value: 'sint maarten (dutch part)',
  },
  {
    cn: 'Slovakia',
    cs: 'SK',
    value: 'slovakia',
  },
  {
    cn: 'Slovenia',
    cs: 'SI',
    value: 'slovenia',
  },
  {
    cn: 'Solomon Islands',
    cs: 'SB',
    value: 'solomon islands',
  },
  {
    cn: 'Somalia',
    cs: 'SO',
    value: 'somalia',
  },
  {
    cn: 'South Africa',
    cs: 'ZA',
    value: 'south africa',
  },
  {
    cn: 'South Georgia and the South Sandwich Islands',
    cs: 'GS',
    value: 'south georgia and the south sandwich islands',
  },
  {
    cn: 'South Sudan',
    cs: 'SS',
    value: 'south sudan',
  },
  {
    cn: 'Spain',
    cs: 'ES',
    value: 'spain',
  },
  {
    cn: 'Sri Lanka',
    cs: 'LK',
    value: 'sri lanka',
  },
  {
    cn: 'Sudan',
    cs: 'SD',
    value: 'sudan',
  },
  {
    cn: 'Suricn',
    cs: 'SR',
    value: 'suricn',
  },
  {
    cn: 'Svalbard and Jan Mayen',
    cs: 'SJ',
    value: 'svalbard and jan mayen',
  },
  {
    cn: 'Swaziland',
    cs: 'SZ',
    value: 'swaziland',
  },
  {
    cn: 'Sweden',
    cs: 'SE',
    value: 'sweden',
  },
  {
    cn: 'Switzerland',
    cs: 'CH',
    value: 'switzerland',
  },
  {
    cn: 'Syrian Arab Republic',
    cs: 'SY',
    value: 'syrian arab republic',
  },
  {
    cn: 'Taiwan, Province of China',
    cs: 'TW',
    value: 'taiwan, province of china',
  },
  {
    cn: 'Tajikistan',
    cs: 'TJ',
    value: 'tajikistan',
  },
  {
    cn: 'Tanzania, United Republic of',
    cs: 'TZ',
    value: 'tanzania, united republic of',
  },
  {
    cn: 'Thailand',
    cs: 'TH',
    value: 'thailand',
  },
  {
    cn: 'Timor-Leste',
    cs: 'TL',
    value: 'timor-leste',
  },
  {
    cn: 'Togo',
    cs: 'TG',
    value: 'togo',
  },
  {
    cn: 'Tokelau',
    cs: 'TK',
    value: 'tokelau',
  },
  {
    cn: 'Tonga',
    cs: 'TO',
    value: 'tonga',
  },
  {
    cn: 'Trinidad and Tobago',
    cs: 'TT',
    value: 'trinidad and tobago',
  },
  {
    cn: 'Tunisia',
    cs: 'TN',
    value: 'tunisia',
  },
  {
    cn: 'Turkey',
    cs: 'TR',
    value: 'turkey',
  },
  {
    cn: 'Turkmenistan',
    cs: 'TM',
    value: 'turkmenistan',
  },
  {
    cn: 'Turks and Caicos Islands',
    cs: 'TC',
    value: 'turks and caicos islands',
  },
  {
    cn: 'Tuvalu',
    cs: 'TV',
    value: 'tuvalu',
  },
  {
    cn: 'Uganda',
    cs: 'UG',
    value: 'uganda',
  },
  {
    cn: 'Ukraine',
    cs: 'UA',
    value: 'ukraine',
  },
  {
    cn: 'United Arab Emirates',
    cs: 'AE',
    value: 'united arab emirates',
  },
  {
    cn: 'United Kingdom',
    cs: 'GB',
    value: 'united kingdom',
  },
  {
    cn: 'United States',
    cs: 'US',
    value: 'united states',
  },
  {
    cn: 'United States Minor Outlying Islands',
    cs: 'UM',
    value: 'united states minor outlying islands',
  },
  {
    cn: 'Uruguay',
    cs: 'UY',
    value: 'uruguay',
  },
  {
    cn: 'Uzbekistan',
    cs: 'UZ',
    value: 'uzbekistan',
  },
  {
    cn: 'Vanuatu',
    cs: 'VU',
    value: 'vanuatu',
  },
  {
    cn: 'Venezuela',
    cs: 'VE',
    value: 'venezuela',
  },
  {
    cn: 'Vietnam',
    cs: 'VN',
    value: 'vietnam',
  },
  {
    cn: 'Virgin Islands, British',
    cs: 'VG',
    value: 'virgin islands, british',
  },
  {
    cn: 'Virgin Islands, U.S.',
    cs: 'VI',
    value: 'virgin islands, u.s.',
  },
  {
    cn: 'Wallis and Futuna',
    cs: 'WF',
    value: 'wallis and futuna',
  },
  {
    cn: 'Western Sahara',
    cs: 'EH',
    value: 'western sahara',
  },
  {
    cn: 'Yemen',
    cs: 'YE',
    value: 'yemen',
  },
  {
    cn: 'Zambia',
    cs: 'ZM',
    value: 'zambia',
  },
  {
    cn: 'Zimbabwe',
    cs: 'ZW',
    value: 'zimbabwe',
  },
]
